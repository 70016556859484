import axios from "axios";

export const agenda = {
  namespaced: true,
  state: {
    update: null,
    agenda: {},
  },
  actions: {
    INDEX: async ({ state, commit }) =>
      axios
        .get("/agenda", { params: { update: state.update } })
        .then(({ data: { agenda: agenda, update: update } }) => {
          commit("UPDATE", update);
          commit("AGENDA", agenda);
        })
        .catch(() => {
          commit("AGENDA", {});
        }),
    UPDATE: ({ dispatch }, { id: id, data: data }) =>
      axios.put(`/reservations/${id}`, data).then(() => dispatch("INDEX")),
    COMPLETE: async ({ commit }, reservation) =>
      axios
        .put(`/reservations/${reservation.id}/complete`)
        .then(() => commit("COMPLETE", reservation.id)),
  },

  mutations: {
    UPDATE: (state, update) => (state.update = update),
    AGENDA: (state, agenda) => (state.agenda = Object.assign({}, agenda)),
    COMPLETE: (state, id) => delete state.agenda[id],
    REMOVE: (state, id) => delete state.agenda[id],
  },
};
