import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import { auth } from "./auth";
import { agenda } from "./agenda";
import { app } from "./app/app";
import { board } from "./board";
import { incoming } from "./incoming";
import { inventory } from "./inventory";
import { movement } from "./movement";
import { reservation } from "./reservation";
import { warehouse } from "./warehouse";
import { wood } from "./wood";

export default new Vuex.Store({
  modules: {
    auth,
    agenda,
    app,
    board,
    incoming,
    inventory,
    movement,
    reservation,
    warehouse,
    wood,
  },
});
