import { notification } from "@/plugins/notification";
import axios from "axios";
import router from "../../router";

export const movement = {
  namespaced: true,
  state: {
    update: null,
    movements: {},
    movement: {},
    query: "",
  },
  actions: {
    INDEX: async ({ state, commit }) =>
      axios
        .get("/movements", { params: { update: state.update } })
        .then(({ data: { movements: movements, update: update } }) => {
          commit("UPDATE", update);
          commit("MOVEMENTS", movements);
        })
        .catch(() => {
          commit("MOVEMENTS", {});
        }),
    RESET: ({ commit }) => commit("MOVEMENT", {}),
    SAVE: (_, movement) =>
      axios
        .post("/movements", movement)
        .then(() => router.push("/movements"))
        .catch((error) =>
          notification.error({ message: error.response.data.message })
        ),
    UPDATE: ({ dispatch }, { id: id, notes: notes }) =>
      axios
        .put(`/movements/${id}`, {
          notes: notes,
        })
        .then(() => dispatch("INDEX")),
    MOVE: ({ dispatch }, { id: id, location: location }) =>
      axios
        .post("/move", { movement: id, location: location })
        .then(() => dispatch("INDEX")),
    DELETE: ({ commit, dispatch }, { id: id, quantity: quantity }) =>
      axios
        .delete(`/movements/${id}`, {
          data: { quantity: quantity },
        })
        .then(() => {
          commit("REMOVE", id);
          dispatch("INDEX");
        }),
  },

  mutations: {
    UPDATE: (state, update) => (state.update = update),
    MOVEMENTS: (state, movements) =>
      (state.movements = Object.assign(state.movements, movements)),
    MOVEMENT: (state, movement) => (state.movement = movement),
    REMOVE: (state, id) => delete state.movements[id],
    QUERY: (state, query) => (state.query = query),
  },
};
