import axios from "axios";
import { CONFIG } from "@/config";
import { BEARER } from "./authorization";
import { notification } from "./notification";
import store from "@/store";

let notify = false;

axios.defaults.baseURL = CONFIG.API_URL;

axios.interceptors.request.use((config) => {
  notify =
    !config.url.includes("/auth") &&
    (config.method == "post" || config.method == "put");
  return config;
}, null);
axios.interceptors.response.use(
  (response) => {
    if (notify) {
      notification.success({});
    }
    return response;
  },
  (error) => {
    if (error.response.status == 401) {
      store.commit("auth/LOGOUT");
    }
    throw error;
  }
);

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

if (BEARER) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + BEARER;
}
