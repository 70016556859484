export default [
  {
    path: "/woods",
    name: "woods",
    component: () => import("../components/modules/wood/WoodList.vue"),
  },
  {
    path: "/woods/new",
    name: "new wood",
    component: () => import("../components/modules/wood/WoodCard.vue"),
  },
  {
    path: "/woods/:id",
    name: "wood",
    component: () => import("../components/modules/wood/WoodCard.vue"),
    props: true,
  },
];
