import axios from "axios";

const user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: {}, user: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    LOGIN: ({ commit }, { username, password }) =>
      new Promise((resolve, reject) => {
        axios.post("/auth/login", { email: username, password: password }).then(
          ({ data }) => {
            commit("AUTH", data);
            resolve();
          },
          (error) => reject(error)
        );
      }),
    LOGOUT: ({ commit }) =>
      new Promise((resolve) =>
        axios.delete("/auth/logout").then(() => {
          commit("LOGOUT");
          resolve();
        })
      ),
  },
  mutations: {
    AUTH: (state, data) => {
      state.status.loggenIn = true;
      state.user = data.user;
      localStorage.setItem("user", JSON.stringify(data.user));
      localStorage.setItem("token", JSON.stringify(data.token));
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + data.token.token;
    },
    LOGOUT: (state) => {
      state.status = {};
      state.user = null;
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    },
  },
};
