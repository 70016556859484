import agenda from "./agenda";
import board from "./board";
import incoming from "./incoming";
import inventory from "./inventory";
import movement from "./movement";
import reservation from "./reservation";
import warehouse from "./warehouse";
import wood from "./wood";

export default [
  {
    path: "/",
    name: "filippo-bini",
    meta: { auth: true },
    component: () => import("../views/FilippoBini.vue"),
    children: [
      {
        path: "/profile",
        name: "profile",
        component: () => import("../components/profile/UserProfile.vue"),
      },
      {
        path: "/scan",
        name: "scan",
        component: () => import("../components/QrcodeScanner.vue"),
      },
    ]
      .concat(agenda)
      .concat(board)
      .concat(incoming)
      .concat(inventory)
      .concat(movement)
      .concat(reservation)
      .concat(warehouse)
      .concat(wood),
  },
];
