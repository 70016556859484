import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

import login from "./login";
import fb from "./fb";

Vue.use(VueRouter);

const routes = login.concat(fb);

const router = new VueRouter({
  mode: "history",
  routes: routes,
});

router.beforeEach((to, from, next) => {
  NProgress.start();
  const loggedIn = localStorage.getItem("user");
  if (to.matched.some((record) => record.meta.auth)) {
    if (!loggedIn) {
      next("/login");
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
