export default [
  {
    path: "/movements",
    name: "movements",
    component: () => import("../components/modules/movement/MovementList.vue"),
  },
  {
    path: "/movements/new",
    name: "new movement",
    component: () => import("../components/modules/movement/MovementCard.vue"),
  },
  {
    path: "/movements/:id",
    name: "movement",
    component: () => import("../components/modules/movement/MovementCard.vue"),
    props: true,
  },
];
