export default [
  {
    path: "/boards",
    name: "boards",
    component: () => import("../components/modules/board/BoardList.vue"),
  },
  {
    path: "/boards/new",
    name: "new board",
    component: () => import("../components/modules/board/BoardCard.vue"),
  },
  {
    path: "/boards/:id",
    name: "board",
    component: () => import("../components/modules/board/BoardCard.vue"),
    props: true,
  },
];
