import "./selectors";

const successIcon = `<svg class="h-6 w-6 text-green-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
</svg>`;
const errorIcon = `<svg class="h-6 w-6 text-red-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>`;
const template = `<div id="notification-{{id}}" class="fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end">
  <div class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto">
    <div class="rounded-lg shadow-xs overflow-hidden">
      <div class="p-4">
        <div class="flex items-start">
          <div class="flex-shrink-0">
            {{icon}}
          </div>
          <div class="ml-3 w-0 flex-1 pt-0.5">
            <p class="text-sm leading-5 font-medium text-gray-900">
              {{title}}
            </p>
            <p class="mt-1 text-sm leading-5 text-gray-500">
              {{message}}
            </p>
          </div>
          <div class="ml-4 flex-shrink-0 flex">
            <button id="remove-notification-{{id}}" class="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150">
              <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>`;

export const notification = {
  success: ({ title = "Salvato", message = "", timeout = 2500 }) => {
    let isVisible;
    const id = Math.floor(Math.random() * 10000);
    const component = template
      .replace(/{{id}}/gi, id)
      .replace("{{title}}", title)
      .replace("{{message}}", message)
      .replace("{{icon}}", successIcon);
    window.$("body").insertAdjacentHTML("beforeend", component);
    isVisible = true;
    const elem = window.$("#notification-" + id);
    const removeButton = window.$("#remove-notification-" + id);
    removeButton.addEventListener("click", () => {
      elem.parentNode.removeChild(elem);
      isVisible = false;
    });
    setTimeout(() => isVisible && elem.parentNode.removeChild(elem), timeout);
  },
  error: ({ title = "Errore", message = "", timeout = 2500 }) => {
    let isVisible;
    const id = Math.floor(Math.random() * 10000);
    const component = template
      .replace(/{{id}}/gi, id)
      .replace("{{title}}", title)
      .replace("{{message}}", message)
      .replace("{{icon}}", errorIcon);
    window.$("body").insertAdjacentHTML("beforeend", component);
    isVisible = true;
    const elem = window.$("#notification-" + id);
    const removeButton = window.$("#remove-notification-" + id);
    removeButton.addEventListener("click", () => {
      elem.parentNode.removeChild(elem);
      isVisible = false;
    });
    setTimeout(() => isVisible && elem.parentNode.removeChild(elem), timeout);
  },
};
