let user = JSON.parse(localStorage.getItem("user"));

let token = JSON.parse(localStorage.getItem("token"));
let bearer = null;
if (!token) {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  bearer = false;
} else {
  bearer = token && token.token ? token.token : false;
}

export const USER = user;
export const BEARER = bearer;
