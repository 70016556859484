import axios from "axios";

export const app = {
  namespaced: true,

  state: {
    user: {},
    fullscreen: false,
  },

  actions: {
    USER: async ({ commit }) =>
      axios.get("/user").then((response) => {
        commit("USER", response.data);
      }),
  },

  mutations: {
    USER: (state, user) => (state.user = user),
  },
  getters: {
    group: (state) => (group) =>
      state.user.groups != undefined && state.user.group.indexOf(group) > -1,
  },
};
