import { notification } from "@/plugins/notification";
import axios from "axios";
import router from "../../router";
// import Index from "flexsearch/dist/module";
import Fuse from "fuse.js";
import FlexSearch from "flexsearch";

export const board = {
  namespaced: true,
  state: {
    update: null,
    boards: {},
    board: {},
    selected: [],
    query: "",
    index: new Fuse([], {
      keys: ["name", "size"],
      includeScore: true,
      includeMatches: true,
      minMatchCharLength: 4,
    }),
    index2: new FlexSearch.Document({
      cache: true,
      document: {
        index: ["name", "size"],
      },
      tokenize: "forward",
    }),
  },
  actions: {
    INDEX: async ({ state, commit }) =>
      axios
        .get("/boards", { params: { update: state.update, preview: true } })
        .then(({ data: { boards: boards, update: update } }) => {
          commit("UPDATE", update);
          commit("BOARDS", boards);
        })
        .catch(() => {
          commit("BOARDS", {});
        }),

    GET: async ({ commit }, board) =>
      axios
        .get(`/boards/${board.id}`)
        .then(({ data: data }) => commit("BOARD", data.data))
        .catch(function () {
          commit("BOARD", {});
        }),
    RESET: ({ commit }) => commit("BOARD", {}),
    SAVE: (_, board) => {
      const req = board.id
        ? axios.put("/boards/" + board.id, board)
        : axios.post("/boards", board);
      req
        .then(() => router.push("/boards/"))
        .catch((error) =>
          notification.error({ message: error.response.data.message })
        );
    },
    DELETE: ({ commit, state }) => {
      commit("REMOVE", state.board.id);
      axios
        .delete("/boards/" + state.board.id)
        .then(() => router.push("/boards"));
    },
    QRCODE: (_, board) =>
      axios
        .get(`/pdf/board/${board.id}`, { responseType: "blob" })
        .then((response) => {
          const url = window.URL.createObjectURL(response.data);
          const link = window.document.createElement("a");
          link.href = url;
          link.setAttribute("target", "_blank");
          link.setAttribute(
            "download",
            `${board.name}-${board.length}x${board.width}x${board.depth}.pdf`
          );
          window.document.body.appendChild(link);
          link.click();
        }),
    SELECT: ({ state, commit }, board) =>
      !state.selected.find((item) => item.id == board.id) &&
      commit("SELECT", board),
    UNSELECT: ({ commit }, index) => commit("UNSELECT", index),
  },

  mutations: {
    UPDATE: (state, update) => (state.update = update),
    BOARDS: (state, boards) => {
      state.boards = Object.assign(state.boards, boards);
      state.index2.add(boards);
      Object.entries(boards).forEach((obj) => state.index.add(obj[1]));
    },
    BOARD: (state, board) => {
      state.board = board;
      if (board.id) {
        state.boards[board.id] = board;
      }
    },
    REMOVE: (state, id) => {
      delete state.boards[id];
      state.index.remove(id);
    },
    QUERY: (state, query) => (state.query = query),
    SELECT: (state, board) => state.selected.push(board),
    UNSELECT: (state, index) => state.selected.splice(index, 1),
  },
};
