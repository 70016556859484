import { notification } from "@/plugins/notification";
import axios from "axios";
import router from "../../router";

export const wood = {
  namespaced: true,
  state: {
    update: null,
    woods: {},
    wood: {},
  },
  actions: {
    INDEX: async ({ state, commit }) =>
      axios
        .get("/woods", { params: { update: state.update } })
        .then(({ data: { woods: woods, update: update } }) => {
          commit("UPDATE", update);
          commit("WOODS", woods);
        })
        .catch(() => {
          commit("WOODS", {});
        }),

    GET: async ({ commit }, wood) =>
      axios
        .get(`/woods/${wood.id}`)
        .then(({ data: data }) => commit("WOOD", data.data))
        .catch(function () {
          commit("WOOD", {});
        }),
    RESET: ({ commit }) => commit("WOOD", {}),
    SAVE: (_, wood) => {
      const req = wood.id
        ? axios.put("/woods/" + wood.id, wood)
        : axios.post("/woods", wood);
      req
        .then(() => router.push("/woods"))
        .catch((error) =>
          notification.error({ message: error.response.data.message })
        );
    },
    DELETE: ({ commit, state }) => {
      commit("REMOVE", state.wood.id);
      axios.delete("/woods/" + state.wood.id).then(() => router.push("/woods"));
    },
  },

  mutations: {
    UPDATE: (state, update) => (state.update = update),
    WOODS: (state, woods) => (state.woods = Object.assign(state.woods, woods)),
    WOOD: (state, wood) => {
      state.wood = wood;
      if (wood.id) {
        state.woods[wood.id] = wood;
      }
    },
    REMOVE: (state, id) => {
      delete state.woods[id];
    },
  },
};
