import axios from "axios";

export const reservation = {
  namespaced: true,
  state: {
    update: null,
    reservations: {},
    query: "",
  },
  actions: {
    INDEX: async ({ state, commit }) =>
      axios
        .get("/reservations", { params: { update: state.update } })
        .then(({ data: { reservations: reservations, update: update } }) => {
          commit("UPDATE", update);
          commit("RESERVATIONS", reservations);
        })
        .catch(() => {
          commit("RESERVATIONS", {});
        }),
    UPDATE: ({ dispatch }, { id: id, data: data }) =>
      axios.put(`/reservations/${id}`, data).then(() => dispatch("INDEX")),
    COMPLETE: async ({ commit }, reservation) =>
      axios
        .put(`/reservations/${reservation.id}/complete`)
        .then(() => commit("COMPLETE", reservation.id)),
    DELETE: ({ commit, dispatch }, { id: id, quantity: quantity }) =>
      axios
        .delete("/reservations/" + id, {
          data: { quantity: quantity },
        })
        .then(() => {
          commit("REMOVE", id);
          dispatch("INDEX");
        }),
  },

  mutations: {
    UPDATE: (state, update) => (state.update = update),
    RESERVATIONS: (state, reservations) =>
      (state.reservations = Object.assign(state.reservations, reservations)),
    COMPLETE: (state, id) => delete state.reservations[id],
    REMOVE: (state, id) => delete state.reservations[id],
    QUERY: (state, query) => (state.query = query),
  },
};
