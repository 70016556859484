<template>
<header class="pt-10 pb-32 bg-emerald-700">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 class="text-3xl leading-9 font-bold text-white capitalize">
          {{ title }}
        </h1>
      </div>
    </header>
</template>
<script>
export default {
    props:['title']
}
</script>