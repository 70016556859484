import axios from "axios";

export const inventory = {
  namespaced: true,
  state: {
    update: null,
    inventories: {},
    query: "",
  },
  actions: {
    INDEX: async ({ state, commit }) =>
      axios
        .get("/inventory", { params: { update: state.update } })
        .then(({ data: { inventories: inventories, update: update } }) => {
          commit("UPDATE", update);
          commit("INVENTORIES", inventories);
        })
        .catch(() => {
          commit("INVENTORIES", {});
        }),
    EXPORT: () =>
      axios
        .get("/inventory/export", { responseType: "blob" })
        .then((response) => {
          const url = window.URL.createObjectURL(response.data);
          const link = window.document.createElement("a");
          link.href = url;
          link.setAttribute("target", "_blank");
          link.setAttribute("download", "Inventario.xlsx");
          window.document.body.appendChild(link);
          link.click();
        }),
  },

  mutations: {
    UPDATE: (state, update) => (state.update = update),
    INVENTORIES: (state, inventories) =>
      (state.inventories = Object.assign(state.inventories, inventories)),
    QUERY: (state, query) => (state.query = query),
  },
};
