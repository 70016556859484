import Vue from "vue";
import dayjs from "dayjs";
import vSelect from "vue-select";
import VueSweetalert2 from "vue-sweetalert2";

import "./plugins";
import App from "./App.vue";
import AppHeader from "./components/core/AppHeader.vue";
import router from "./router";
import store from "./store";

import "vue-select/dist/vue-select.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "./assets/tailwind.css";

Vue.use(VueSweetalert2);
Vue.component("v-select", vSelect);
Vue.component("app-header", AppHeader);
Vue.config.productionTip = false;

Vue.filter("prettydate", (date) => dayjs(date).format("DD/MM/YYYY"));
Vue.filter("prettyhour", (hour) => hour.substring(0, 5));
Vue.filter("ellipsis", (text) =>
  text ? text.trim().substring(0, 100) + "..." : ""
);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
