export default [
  {
    path: "/warehouses",
    name: "warehouses",
    component: () =>
      import("../components/modules/warehouse/WarehouseList.vue"),
  },
  {
    path: "/warehouses/new",
    name: "new warehouse",
    component: () =>
      import("../components/modules/warehouse/WarehouseCard.vue"),
  },
  {
    path: "/warehouses/:id",
    name: "warehouse",
    component: () =>
      import("../components/modules/warehouse/WarehouseCard.vue"),
    props: true,
  },
];
