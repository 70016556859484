import { notification } from "@/plugins/notification";
import axios from "axios";
import router from "../../router";

export const warehouse = {
  namespaced: true,
  state: {
    update: null,
    warehouses: {},
    locations: {},
    location: {},
  },
  actions: {
    INDEX: async ({ state, commit }) =>
      axios
        .get("/locations", { params: { update: state.update } })
        .then(
          ({
            data: {
              warehouses: warehouses,
              locations: locations,
              update: update,
            },
          }) => {
            commit("UPDATE", update);
            commit("LOCATIONS", locations);
            commit("WAREHOUSES", warehouses);
          }
        )
        .catch(() => {
          commit("LOCATIONS", {});
          commit("WAREHOUSES", {});
        }),

    GET: async ({ commit }, location) =>
      axios
        .get(`/locations/${location.id}`)
        .then(({ data: data }) => commit("LOCATION", data.data))
        .catch(function () {
          commit("LOCATION", {});
        }),
    RESET: ({ commit }) => commit("LOCATION", {}),
    SAVE: (_, location) => {
      const req = location.id
        ? axios.put("/locations/" + location.id, location)
        : axios.post("/locations", location);
      req
        .then(() => router.push("/warehouses"))
        .catch((error) =>
          notification.error({ message: error.response.data.message })
        );
    },
    DELETE: ({ commit, state }) => {
      commit("REMOVE", state.location.id);
      axios
        .delete("/locations/" + state.location.id)
        .then(() => router.push("/warehouses"));
    },
    QRCODE: (_, location) =>
      axios
        .get(`/pdf/location/${location.id}`, { responseType: "blob" })
        .then((response) => {
          const url = window.URL.createObjectURL(response.data);
          const link = window.document.createElement("a");
          link.href = url;
          link.setAttribute("target", "_blank");
          link.setAttribute(
            "download",
            `${location.warehouse}-${location.code}.pdf`
          );
          window.document.body.appendChild(link);
          link.click();
        }),
  },

  mutations: {
    UPDATE: (state, update) => (state.update = update),
    WAREHOUSES: (state, warehouses) =>
      (state.warehouses = Object.assign(state.warehouses, warehouses)),
    LOCATIONS: (state, locations) =>
      (state.locations = Object.assign(state.locations, locations)),
    LOCATION: (state, location) => {
      state.location = location;
      if (location.id) {
        state.locations[location.id] = location;
      }
    },
    REMOVE: (state, id) => {
      delete state.locations[id];
    },
  },
};
