import axios from "axios";

export const incoming = {
  namespaced: true,
  state: {
    update: null,
    incomings: {},
    query: "",
  },
  actions: {
    INDEX: async ({ commit }) =>
      axios
        .get("/incomings")
        .then(({ data: { incomings: incomings, update: update } }) => {
          commit("UPDATE", update);
          commit("INCOMINGS", incomings);
        })
        .catch(() => {
          commit("INCOMINGS", {});
        }),
    UPDATE: ({ dispatch }, { id: id, data: data }) =>
      axios.put(`/incomings/${id}`, data).then(() => dispatch("INDEX")),
    MOVE: ({ dispatch }, { id: id, location: location }) =>
      axios
        .post("/move", { movement: id, location: location })
        .then(() => dispatch("INDEX")),
    DELETE: ({ commit, dispatch }, { id: id, quantity: quantity }) =>
      axios
        .delete("/incomings/" + id, {
          data: { quantity: quantity },
        })
        .then(() => {
          commit("REMOVE", id);
          dispatch("INDEX");
        }),
  },

  mutations: {
    UPDATE: (state, update) => (state.update = update),
    INCOMINGS: (state, incomings) =>
      (state.incomings = state.incomings = incomings),
    REMOVE: (state, id) => delete state.incomings[id],
    QUERY: (state, query) => (state.query = query),
  },
};
